export const SHOW_TOAST = "SHOW_TOAST";
export const HIDE_TOAST = "HIDE_TOAST";

export const showToast = (payload) => {
    return dispatch => {
        dispatch({
            type: SHOW_TOAST,
            payload
        });
    };
};
export const hideToast = (payload) => {
    return dispatch => {
        dispatch({
            type: HIDE_TOAST
        });
    };
};